import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode  from '@mapbox/mapbox-gl-draw-static-mode';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import React, { useEffect, useRef } from 'react';
import styles from './libreMap.module.css';
import { Feature } from '@turf/turf';

export type DrawData = {
  id: string;
  action: string;
  coords: any;
  type: string;
  feature: Feature
};

export type StartupOptions = {
  latitude: number;
  longitude: number;
  zoom: number;
  apiKey: string;
}

export interface MapLibreProps {
  startupOptions: StartupOptions;
  //drawControlsVisible: boolean; //For future use.  Not working 100% yet
  keyboardEnabled: boolean;
  onMapCreated?: (map: maplibregl.Map) => void;
  onDrawControlCreated?: (drawControl: MapboxDraw) => void;
  onBearingChanged?: (bearing: number) => void;
  onDrawDataUpdated?: (drawData?: DrawData) => void;
  onDrawModeChange?: (mode: string) => void;
  onKeyDown?: (key: KeyboardEvent) => void;
}

export const MapLibre = ({
  startupOptions,
  onMapCreated,
  onDrawControlCreated,
  onDrawModeChange,
  keyboardEnabled,
  onBearingChanged,
  onDrawDataUpdated,
  onKeyDown 
} : MapLibreProps): JSX.Element => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const drawControls = useRef(null);

  //Update the draw data (so parent can use/react to it)
  const updateDrawData = (drawId: string, drawGeometry: any, action: string, feature: Feature) =>
    onDrawDataUpdated?.({
      id: drawId,
      type: drawGeometry.type,
      action: action,
      coords: drawGeometry.coordinates,
      feature: feature
    });

  //Clears the draw data
  const clearDrawData = () => onDrawDataUpdated?.(null);

  //Occurs when the map is rotated
  const handleRotate = () => onBearingChanged?.(map.current.getBearing());

  //Occurs when any drawing occurs on map
  const handleDrawChange = (event) => {
    if (event.features.length > 0) {
      updateDrawData(event.features[0].id, event.features[0].geometry, event.type, event.features[0])
    } else {
      clearDrawData();
    }
  };

  //Occurs when mouse clicked on the map
  const handleMouseClick = (event: maplibregl.MapMouseEvent) => {

    //Make the clicked point larger as to ensure the polygon is returned as a feature when
    //a circle is clicked slightly outside the polygon
    //https://docs.mapbox.com/mapbox-gl-js/example/queryrenderedfeatures-around-point/
    const expandedPoint = [
      [event.point.x - 5, event.point.y - 5],
      [event.point.x + 5, event.point.y + 5]
    ];

    //Fetch all the features at the clicked point (can be multiple like line, point, vertex, polygon)
    //https://gis.stackexchange.com/questions/284864/how-to-get-geometry-of-clicked-feature
    const features = map.current.queryRenderedFeatures(expandedPoint);

    //Find the POLYGON feature (IE: The shape that was/is drawn)
    const polygonFeature = features.find((feature) => {
      return (feature.properties['meta:type'] === 'Polygon');
    });

    //If POLYGON is still being drawn, then ignore click
    if (polygonFeature && (polygonFeature.properties['mode'] === 'draw_polygon' || polygonFeature.properties['active'] === 'true')) {
      return;
    }

    //POLYGON found and is being selected (mode = simple_select)
    else if (polygonFeature && polygonFeature.properties['mode'] === 'simple_select') {
      updateDrawData(polygonFeature.properties.id, polygonFeature.geometry, "draw.select", polygonFeature);
    }

    //POLYGON found but it won't be selected in map, so clear draw data
    else if (polygonFeature && polygonFeature.properties['mode'] === 'direct_select') {
      clearDrawData();
    }

    //If no POLYGON found, clear draw data
    else {
      clearDrawData();
    }
  };

  //Occurs when key pressed on the map
  const handleKeyDown = (event: KeyboardEvent) => {
    console.log(event);
    onKeyDown?.(event);
  };

  //Initialization of the map
  useEffect(() => {

    if (map.current) return;

    //Create the map
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/hybrid/style.json?key=${startupOptions.apiKey}`,
      center: {
        lng: startupOptions.longitude,
        lat: startupOptions.latitude
      },
      zoom: startupOptions.zoom,
      keyboard: keyboardEnabled,
    });

    //Call the parent to let them know the map is ready
    if (onMapCreated)
      onMapCreated(map.current);

    //Add the scale
    map.current.addControl(new maplibregl.ScaleControl());

    //Create a draw control but don't add it to the map.  Will be handled by a property.
    var modes = MapboxDraw.modes;
    (modes as any).static = StaticMode;  //Add the static mode as this is a separate package
    drawControls.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: false,
        trash: false
      },
      /* KEEP FOR REFERENCE ON HOW TO STYLE
      userProperties: true, 
      styles: [ {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all', ['==', 'mode', 'static'],
          ['==', '$type', 'Polygon']
        ],
        'paint': {
          'fill-color': '#3bb2d0',
          'fill-outline-color': '#3bb2d0',
          'fill-opacity': 0.4
        }
      }]*/
    });

    map.current.addControl(drawControls.current, 'top-left');

    //Call the parent to let them know the draw controls is ready
    if (onDrawControlCreated)
      onDrawControlCreated(drawControls.current);

    //Hook into events
    map.current.on('draw.modechange', handleDrawControlsModeChange);
    map.current.on('load', () => {
      map.current.getCanvas().focus();
      map.current.getCanvas().addEventListener('keydown', handleKeyDown);
    });

    //NB: Always remember to remove events when component is unmounted
    return () => {
      map.current.off('draw.modechange', handleDrawControlsModeChange);
      map.current.off('load', () => {
        map.current.getCanvas().removeEventListener('keydown', handleKeyDown);
      });
    }
  });

  //Occurs when any drawing occurs on map
  const handleDrawControlsModeChange = (event: MapboxDraw.DrawModeChangeEvent) => {
    onDrawModeChange?.(`${event.mode}`);
  };

  //Hook into events for onDrawDataUpdated()
  useEffect(() => {
    map.current.on('draw.create', handleDrawChange);
    map.current.on('draw.delete', handleDrawChange);
    map.current.on('draw.update', handleDrawChange);
    map.current.on('click', handleMouseClick);          //Left click
    map.current.on('contextmenu', handleMouseClick);    //Right click

    //NB: Always remember to remove events when component is unmounted
    return () => {
      map.current.off('draw.create', handleDrawChange);
      map.current.off('draw.delete', handleDrawChange);
      map.current.off('draw.update', handleDrawChange);
      map.current.off('click', handleMouseClick);
      map.current.off('contextmenu', handleMouseClick);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDrawDataUpdated]);

  //Hook into events for onBearingChanged()
  useEffect(() => {
    map.current.on('rotate', handleRotate);

    //NB: Always remember to remove events when component is unmounted
    return () => {
      map.current.off('rotate', handleRotate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBearingChanged]);


  /* For future use.  Not working 100% yet
  //Used to toggle the draw controls visible/hidden
  useEffect(() => {
    //Ignore if not yet initialised
    if (drawControlsVisible === undefined) return;
    if (drawControls.current === undefined) return;

    //Either add or remove draw controls
    if ((drawControlsVisible) && (!map.current.hasControl(drawControls.current))) {
        map.current.addControl(drawControls.current, 'top-left'); 
    } else if ((!drawControlsVisible) && (map.current.hasControl(drawControls.current))) {
        map.current.removeControl(drawControls.current);
    }
  }, [drawControlsVisible, drawControls]); */

  return (
      <div ref={mapContainer} className={styles.map} />
  );
}