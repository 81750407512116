import { Postcode } from './models';

const url = `https://api.postcodes.io/postcodes/{postcode}`;

//Get postcode data from the postcodes.io API
export const getPostcode = async (postcode: string) : Promise<Postcode> => {

    const response = await fetch(url.replace('{postcode}', postcode));

    //Throw error if the response is not ok
    if (response.status !== 200) {
        throw new Error(await response.text());
    }

    //Return the postcode data
    const data = await response.json();
    return data.result;
}