
import React from "react";
import {Routes , Route } from "react-router-dom"
import { PlotMapPage } from "./pages/plotMapPage"
import { ToastContainerDefault } from './components/ui/toast';

import "./styles/global.css";
import "./styles/muiStyleOverrides.css";
import "./styles/mapBoxOverrides.css";

function App(){
   return (
      <>
         <Routes>
            <Route path="/" element={<PlotMapPage/> } />
            <Route path="/plotmap" element={<PlotMapPage/> } />
         </Routes>
         <ToastContainerDefault />
      </>
   )
}
export default App