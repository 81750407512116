import React from 'react';
import { useEffect } from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './toast.module.css';


export const ToastAutoClose = {
  Default: 'Default',
  Always: 'Always',
  Never: 'Never',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ToastAutoClose = typeof ToastAutoClose[keyof typeof ToastAutoClose];

export const ToastKind = {
  Info: 'Info',
  Success: 'Success',
  Error: 'Error',
  Warning: 'Warning',
  Default: 'Default',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ToastKind = typeof ToastKind[keyof typeof ToastKind];

export interface ToastProps {
  toastKind: ToastKind;
  message: string | (() => JSX.Element);
  autoClose: ToastAutoClose;
  toastId?: string;
}

export const ToastContainerDefault = (): JSX.Element => {
  return (
    <ToastContainer
      className={styles.container}
      position="top-center"
      autoClose={2500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover
      theme="colored"
    />
  );
};

export const Toast = ({ toastKind, message, autoClose, toastId }: ToastProps): JSX.Element => {
  useEffect(() => {
    showToast({ toastKind, message, autoClose, toastId });
  }, [toastKind, message, autoClose, toastId ]);

  return <ToastContainerDefault />;
};

export const showToast = ({ toastKind, message, autoClose, toastId, }: ToastProps) => {
  const options: ToastOptions = {};
  options.toastId = toastId ? toastId : new Date().getTime();

  //Set the auto close option
  if (autoClose === ToastAutoClose.Always) options.autoClose = 5000;
  if (autoClose === ToastAutoClose.Never) options.autoClose = false; //Note: TRUE is not a valid value here
  if (autoClose === ToastAutoClose.Default) {
    if (toastKind === ToastKind.Error) options.autoClose = false;
  }

  //Show the toast
  if (message) {
    if (toastKind === ToastKind.Info) toast.info(message, options);
    else if (toastKind === ToastKind.Error) toast.error(message, options);
    else if (toastKind === ToastKind.Success) toast.success(message, options);
    else if (toastKind === ToastKind.Warning) toast.warning(message, options);
    else toast(`${message}`);
  }
};
