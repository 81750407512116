import React, { useState, useEffect } from "react";
import { Button, Dialog,DialogActions, DialogContent,  DialogTitle, TextField} from '@mui/material';
import { DateTime } from "luxon";

import { Plot } from "../../models/plot";
import { deepClonePlot } from "../../helpers/plotHelper";
import { postPlot } from "../../apiClients/plotSubmission/api";
import { ToastAutoClose, ToastKind, showToast } from "../ui";
import globalStyles from '../../styles/global.module.css';
import { isValidEmailAddress } from "../../helpers/emailHelper";

export interface SubmitPlotDialogProps {
  open: boolean;
  plot: Plot;
  onSubmitted: (plot: Plot) => void;
  onCancelled: () => void;
}

export const SubmitPlotDialog = ({
  open,
  plot,
  onSubmitted,
  onCancelled
}: SubmitPlotDialogProps): JSX.Element => {

  const defaultInputRef = React.useRef(null);
  const [emailAddress, setEmailAddress] = useState<string>(null);
  const [confirmEmailAddress, setConfirmEmailAddress] = useState<string>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>(null);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  useEffect(() => {
    //Reset the states on dialog open
    if (open) {
      setEmailAddress('');
      setConfirmEmailAddress('');
      setEmailErrorMessage(null);
      setDisableSubmit(false);
    }

    //Delay setting focus as default "autofocus" doesn't seem to work
    //https://github.com/mui/material-ui/issues/1594
    const timeout = setTimeout(() => {
      defaultInputRef.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };

  }, [open]);


  //Occurs when CANCEL button is clicked
  const handleCancelClick = async () => {
    if (onCancelled) onCancelled();
  }

  //Occurs when CANCEL button is clicked
  const handleSubmitClick = async () => {

    //Do email validation
    if (!emailAddress || !confirmEmailAddress) {
      setEmailErrorMessage("Email address and confirmation email address are required.");
      return;
    } else if (emailAddress !== confirmEmailAddress) {
      setEmailErrorMessage("Email addresses must match.");
      return;
    } else if (!isValidEmailAddress(emailAddress)) {
      setEmailErrorMessage("Email address specified is not valid.");
      return;
    };

    //No error, reset message and continue
    setEmailErrorMessage(null);

    //Update the submission info
    plot.submissionInfo = {
      ownerEmailAddress: emailAddress,
      submitDate: DateTime.now(),
      submitEmailAddress: window.__RUNTIME_CONFIG__.REACT_APP_PLOT_SUBMISSION_EMAIL
    };

    //Clone the plot removing the map draw data
    var clonedPlot = deepClonePlot(plot, true);
    console.log(JSON.stringify(clonedPlot));

    //Submit the plot
    try {
      await postPlot(clonedPlot);
      setDisableSubmit(true);
    } catch (error) {
      showToast({
        message: error.message,
        toastKind: ToastKind.Success,
        autoClose: ToastAutoClose.Never
      });
    }

    //Call the parent function
    if (onSubmitted) onSubmitted(plot);
  }

  //Occurs when DUMP JSON button is clicked
  const handleDumpJsonClick = async () => {

    //Clone the plot removing the map draw data
    const clonedPlot = deepClonePlot(plot, true);

    //Update the submission info with dummy data
    clonedPlot.submissionInfo = {
      ownerEmailAddress: emailAddress,
      submitDate: DateTime.now(),
      submitEmailAddress: window.__RUNTIME_CONFIG__.REACT_APP_PLOT_SUBMISSION_EMAIL
    };

    //Dump the plot to console
    console.log(JSON.stringify(clonedPlot));
  }

  return (
    <Dialog className={globalStyles.Dialog} open={open}>
      <DialogTitle className={globalStyles.dialogTitle}>Submit Plot</DialogTitle>

      <DialogContent>
        <div>
          <p>
            You are about to submit your plot <b>{plot.name}</b> consisting of <b>{plot.hedgeRows.length}</b> hedgerows to Carbon Keepers.
          </p>
          <p>
            Once submitted, you will not be able to edit your plot details.  If you wish to check or amend your plot details before submission, click <b>Cancel</b>.
          </p>
          <p>
             Your email address specified below will be used to send you a copy of your carbon report once finalised.
          </p>
        </div>

        <div className={globalStyles.inputControl}>
          <label>Email Address</label>
          <TextField
            inputRef={defaultInputRef}
            required
            fullWidth
            variant="outlined"
            id="emailAddress"
            onChange={(event) => { setEmailAddress(event.target.value)}}
            error={!!emailErrorMessage}
            autoComplete="off" //Disable auto complete
          />
        </div>

        <div className={globalStyles.inputControl}>
          <label>Confirm Email Address</label>
          <TextField
            required
            fullWidth
            variant="outlined"
            id="conformEmailAddress"
            onChange={(event) => { setConfirmEmailAddress(event.target.value)}}
            error={!!emailErrorMessage}
            autoComplete="off" //Disable auto complete
            helperText={emailErrorMessage}
          />
        </div>

      </DialogContent>

      <DialogActions className={globalStyles.dialogActionsAlignRight}>
        {window.__RUNTIME_CONFIG__.REACT_APP_DEBUG === "true" && (
          <Button
            className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularRed}`} onClick={handleDumpJsonClick}>
            DEBUG: Dump Plot to console
          </Button>
        )}
        <Button
          className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey}`} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          disabled={disableSubmit}
          className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularPrimary}`} onClick={handleSubmitClick}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};