import React, { useReducer } from "react";
import { useState, useEffect } from "react";
import { DateTime } from "luxon";

import { Help } from '@mui/icons-material';
import {
  Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle,
  Divider, MenuItem, OutlinedInput, Select,
  Button, FormHelperText, FormControl, TextField
} from '@mui/material';


import globalStyles from '../../styles/global.module.css';
import styles from './maintainHedgerowDialog.module.css';
import { InformationDialog } from "./informationDialog";
import {
  Hedgerow, HedgerowPlantTypes, HedgerowAge, HedgerowCondition, HedgerowStyle, 
  HedgerowFertiliser, HedgerowHeight, HedgerowWidth, HedgerowTreeTypes,
  getHedgerowWidthDescription, getHedgerowHeightDescription, getHedgerowAgeDescription,
  getHedgerowConditionDescription, getHedgerowStyleDescription, getHedgerowFertiliserDescription
} from '../../models/plot';

export interface MaintainHedgerowDialogProps {
  open: boolean;
  canEdit: boolean;
  hedgerow: Hedgerow;
  onUpdated: (hedgerow: Hedgerow) => void;
  onCancelled: () => void;
}

export const MaintainHedgerowDialog = ({
  open,
  canEdit,
  hedgerow,
  onUpdated,
  onCancelled
}: MaintainHedgerowDialogProps): JSX.Element => {

  const [draftHedgerow, setDraftHedgerow] = useState<Hedgerow>(JSON.parse(JSON.stringify(hedgerow)));
  const [helpVisible, setHelpVisible] = useState<boolean>(false);
  const [helpTitle, setHelpTitle] = useState<string>(null);
  const [helpContent, setHelpContent] = useState<(() => JSX.Element)>(null);
  const [showErrorText, setShowErrorText] = useState<boolean>(false);

  //Will force a re-render of the component
  const forceUpdate = useReducer(() => ({}), {})[1] as () => void

  useEffect(() => {
    if (open) {
      setDraftHedgerow(JSON.parse(JSON.stringify(hedgerow))); //Deep clone
      setHelpVisible(false);
      setShowErrorText(false);
      forceUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  //Occurs when CANCEL button is clicked
  const onClickCancel = () => {
    if (onCancelled) onCancelled();
  }

  //Occurs when SAVE button is clicked
  const onClickSave = () => {
    draftHedgerow.lastUpdated = DateTime.now();
    onUpdated?.(draftHedgerow);
  }

  //Occurs when the hedge names is changed
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.name = event.target.value;
    forceUpdate();
  }

  //Occurs when the hedgerow age is changed
  const handleHedgerowAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.age = event.target.value as HedgerowAge;
    forceUpdate();
  }

  //Occurs when the hedgerow condition is changed
  const handleHedgerowConditionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.condition = event.target.value as HedgerowCondition;
    forceUpdate();
  }

  //Occurs when the hedgerow style is changed
  const handleHedgerowStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.style = event.target.value as HedgerowStyle;
    forceUpdate();
  }

  //Occurs when the hedgerow style is changed
  const handleHedgerowFertiliserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.fertiliser = event.target.value as HedgerowFertiliser;
    forceUpdate();
  }

  //Occurs when the plants types are changed
  const handleHedgerowPlantTypesChange = (event, values) => {
    draftHedgerow.plantTypes = values;
    forceUpdate();
  }

  //Occurs when the hedgerow height is changed
  const handleHedgerowHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.height = event.target.value as HedgerowHeight;
    forceUpdate();
  }

  //Occurs when the hedgerow width is changed
  const handleHedgerowWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.width = event.target.value as HedgerowWidth;
    forceUpdate();
  }

  //Occurs when the hedgerow base canopy is changed
  const handleHedgerowBaseCanopyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.baseCanopyMetres = parseFloat(event.target.value);
    forceUpdate();
  }

  //Occurs when the plants types are changed
  const handleHedgerowTreeTypesChange = (event, values) => {
    draftHedgerow.treeTypes = values;
    forceUpdate();
  }

  //Occurs when the notes are changed
  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    draftHedgerow.notes = event.target.value;
    forceUpdate();
  }

  //Intercept key down on (number) inputs and ignore below keys
  const handleKeyDownNumberOnly = (event: { keyCode: number; preventDefault: () => void; }) => {
    if (event.keyCode === 69    //"e"
      ||event.keyCode === 189)  //"-"
      event.preventDefault();
  }

  const handleHelpForHedgrerowCondition = () => {
    
    //Create the HTML message (JSX Element) to be display
    const messageComponent = () => (
      <>
        The hedgerow condition is based on the <b>Adams Hedgerow Management Scale</b>.<br/><br/>
        For more information on the hedgerow condition option to choose, browse 
        to <a target="_blank" rel="noopener noreferrer" href="https://hedgerowsurvey.ptes.org/hedge-structures"> Adams Hedgerow Management Scale</a> for
        a more detailed explanation.
      </>
    );

    setHelpTitle("About Hedgerow Condition");
    setHelpContent(messageComponent);
    setHelpVisible(true);
  }

  return (
    <>
      {/* No hedgerow specified, so don't show dialog as dialog needs and instance of a Hedgerow to function*/}
      {!draftHedgerow && (
        <></>
      )}

      {/* Hedgerow specified, show dialog*/}
      {draftHedgerow && (

      <Dialog className={globalStyles.dialog} open={open} maxWidth="lg">
        <DialogTitle className={globalStyles.dialogTitle}>Hedgerow Details</DialogTitle>

        <DialogContent className={`${globalStyles.dialogContent} ${styles.contentContainer}`}>

            {/* LEFT SIDE INPUTS */}
            <div className={styles.contentInnerContainer}>

              {/* Hedgerow Name */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={!draftHedgerow.name}>

                <label>Name</label>
                <TextField
                  disabled={!canEdit}
                  required
                  fullWidth
                  variant="outlined"
                  id="hedgerowName"
                  value={draftHedgerow.name ? draftHedgerow.name : ""}
                  onChange={handleNameChange}
                  error={!draftHedgerow.name}
                  helperText={showErrorText && !draftHedgerow.name ? "Name is required" : ""}
                  autoComplete="off"
                />
              </FormControl>


              {/* Hedgerow Age */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={!draftHedgerow.age}>

                <label>Age</label>

                <Select
                  disabled={!canEdit}
                  className={globalStyles.select}
                  labelId="hedgerowAge"
                  id="hedgerowAge"
                  input={<OutlinedInput />}
                  onChange={handleHedgerowAgeChange}
                  value={draftHedgerow.age ? draftHedgerow.age : ""}
                >
                  {Object.keys(HedgerowAge).map((key) => (
                    <MenuItem key={key} value={HedgerowAge[key]}>
                      {getHedgerowAgeDescription(HedgerowAge[key])}
                    </MenuItem>
                  ))}
                </Select>

                {showErrorText && !draftHedgerow.age && (
                  <FormHelperText>Age is required</FormHelperText>
                )}
              </FormControl>


              {/* Hedgerow Condition */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={!draftHedgerow.condition}>

                <div className={globalStyles.labelHelp}>
                  <label>Condition</label>
                  <Help fontSize="small" onClick={handleHelpForHedgrerowCondition} />
                </div>

                <Select
                  disabled={!canEdit}
                  className={globalStyles.select}
                  labelId="hedgerowCondition"
                  id="hedgerowCondition"
                  input={<OutlinedInput />}
                  onChange={handleHedgerowConditionChange}
                  value={draftHedgerow.condition ? draftHedgerow.condition : ""}
                >
                  {Object.keys(HedgerowCondition).map((key) => (
                    <MenuItem key={key} value={HedgerowCondition[key]}>
                      {getHedgerowConditionDescription(HedgerowCondition[key])}
                    </MenuItem>
                  ))}
                </Select>

                {showErrorText && !draftHedgerow.condition && (
                  <FormHelperText>Condition is required</FormHelperText>
                )}
              </FormControl>


              {/* Hedgerow Style */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={!draftHedgerow.style}>

                <label>Management Style</label>

                <Select
                  disabled={!canEdit}
                  className={globalStyles.select}
                  labelId="hedgerowStyle"
                  id="hedgerowStyle"
                  input={<OutlinedInput />}
                  onChange={handleHedgerowStyleChange}
                  value={draftHedgerow.style ? draftHedgerow.style : ""}
                >
                  {Object.keys(HedgerowStyle).map((key) => (
                    <MenuItem key={key} value={HedgerowStyle[key]}>
                      {getHedgerowStyleDescription(HedgerowStyle[key])}
                    </MenuItem>
                  ))}
                </Select>

                {showErrorText && !draftHedgerow.style && (
                  <FormHelperText>Management style is required</FormHelperText>
                )}
              </FormControl>


              {/* Hedgerow Fertiliser */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={!draftHedgerow.fertiliser}>

                <label>Fertiliser</label>

                <Select
                  disabled={!canEdit}
                  className={globalStyles.select}
                  labelId="hedgerowFertiliser"
                  id="hedgerowFertiliser"
                  input={<OutlinedInput />}
                  onChange={handleHedgerowFertiliserChange}
                  value={draftHedgerow.fertiliser ? draftHedgerow.fertiliser : ""}
                >
                  {Object.keys(HedgerowFertiliser).map((key) => (
                    <MenuItem key={key} value={HedgerowFertiliser[key]}>
                      {getHedgerowFertiliserDescription(HedgerowFertiliser[key])}
                    </MenuItem>
                  ))}
                </Select>

                {showErrorText && !draftHedgerow.fertiliser && (
                  <FormHelperText>Fertilizer option is required</FormHelperText>
                )}
              </FormControl>


              {/* Hedgerow plant types */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={draftHedgerow.plantTypes.length === 0}>

                <label>Plant Types</label>

                <Autocomplete
                  disabled={!canEdit}
                  multiple
                  freeSolo
                  disableClearable
                  options={HedgerowPlantTypes.sort()}
                  onChange={handleHedgerowPlantTypesChange}
                  value={draftHedgerow.plantTypes}
                  renderInput={(params) => (
                    <TextField
                      error={draftHedgerow.plantTypes.length === 0}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />

                {/* Error text for no selection */}
                {showErrorText && draftHedgerow.plantTypes.length === 0 && (
                  <FormHelperText>At least one plant types must be selected</FormHelperText>
                )}
              </FormControl>

            </div>

            <Divider className={globalStyles.verticalDivider} orientation="vertical" flexItem />

            {/* RIGHT SIDE INPUTS */}
            <div className={styles.contentInnerContainer}>

              {/* Hedgerow Height */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={!draftHedgerow.height}>

                <label>Average Height</label>

                <Select
                  disabled={!canEdit}
                  className={globalStyles.select}
                  labelId="hedgerowHeight"
                  id="hedgerowHeight"
                  input={<OutlinedInput />}
                  onChange={handleHedgerowHeightChange}
                  value={draftHedgerow.height ? draftHedgerow.height : ""}
                >
                  {Object.keys(HedgerowHeight).map((key) => (
                    <MenuItem key={key} value={HedgerowHeight[key]}>
                      {getHedgerowHeightDescription(HedgerowHeight[key])}
                    </MenuItem>
                  ))}
                </Select>

                {showErrorText && !draftHedgerow.height && (
                  <FormHelperText>Height is required</FormHelperText>
                )}
              </FormControl>


              {/* Hedgerow Width */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={!draftHedgerow.width}>

                <label>Average Width</label>

                <Select
                  disabled={!canEdit}
                  className={globalStyles.select}
                  labelId="hedgerowHeight"
                  id="hedgerowHeight"
                  input={<OutlinedInput />}
                  onChange={handleHedgerowWidthChange}
                  value={draftHedgerow.width ? draftHedgerow.width : ""}
                >
                  {Object.keys(HedgerowWidth).map((key) => (
                    <MenuItem key={key} value={HedgerowWidth[key]}>
                      {getHedgerowWidthDescription(HedgerowWidth[key])}
                    </MenuItem>
                  ))}
                </Select>

                {showErrorText && !draftHedgerow.width && (
                  <FormHelperText>Width is required</FormHelperText>
                )}
              </FormControl>


              {/* Amount of fertilizer */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}
                error={!draftHedgerow.baseCanopyMetres || draftHedgerow.baseCanopyMetres <= 0}>
                <label>Base Canopy (metres)</label>

                <TextField
                  disabled={!canEdit}
                  required
                  fullWidth
                  variant="outlined"
                  id="baseCanopy"
                  type="number"
                  onChange={handleHedgerowBaseCanopyChange}
                  onKeyDown={handleKeyDownNumberOnly}   //Only allow numbers
                  value={draftHedgerow.baseCanopyMetres ? draftHedgerow.baseCanopyMetres : ""}
                  error={!draftHedgerow.baseCanopyMetres || draftHedgerow.baseCanopyMetres <= 0}
                  helperText={showErrorText && (!draftHedgerow.baseCanopyMetres || draftHedgerow.baseCanopyMetres <= 0) ? "Value greater than 0 required" : ""}
                  autoComplete="off" //Disable auto complete
                />
              </FormControl>

              {/* Hedgerow Tree types */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}>

                <label>Tree Types</label>

                <Autocomplete
                  disabled={!canEdit}
                  multiple
                  freeSolo
                  disableClearable
                  options={HedgerowTreeTypes.sort()}
                  onChange={handleHedgerowTreeTypesChange}
                  value={draftHedgerow.treeTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </FormControl>

              {/* Notes */}
              <FormControl
                fullWidth
                className={globalStyles.inputControl}>
                <label>Notes</label>

                <TextField
                  disabled={!canEdit}
                  fullWidth
                  variant="outlined"
                  multiline
                  id="notes"
                  type="text"
                  onChange={handleNotesChange}
                  value={draftHedgerow.notes}
                  autoComplete="off" //Disable auto complete
                />
              </FormControl>

            </div>

        </DialogContent>

        <DialogActions className={globalStyles.dialogActionsAlignRight}>
          <Button className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey}`} onClick={onClickCancel}>
            Cancel
          </Button>
          {canEdit && (
            <Button className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularPrimary}`} onClick={onClickSave}>
              Save
            </Button>
          )}
        </DialogActions>

      </Dialog>
      )}

      <InformationDialog
        open={helpVisible}
        title={helpTitle}
        jsxDescription={helpContent}
        onOkClick={() => setHelpVisible(false)}
      />
    </>
  );
};
