import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import globalStyles from '../../styles/global.module.css';

export interface InformationDialogProps {
  open: boolean;
  title: string;
  description?: string;
  jsxDescription?: () => JSX.Element;
  onOkClick: () => void;
}

export const InformationDialog = ({
  open,
  title,
  description,
  jsxDescription,
  onOkClick

}: InformationDialogProps): JSX.Element => {
  
  //Occurs when OK button is clicked
  const handleOkClick = () => {
    onOkClick();
  };

  let desc = null;

  if(description) {
     desc = (<span dangerouslySetInnerHTML={{ __html: description}} /> );
  }
  else if(jsxDescription) {
     desc = jsxDescription;
  }

  return (
    <Dialog className={globalStyles.Dialog} open={open}>
      <DialogTitle className={globalStyles.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={globalStyles.dialogContent}>
        {desc}
      </DialogContent>
      <DialogActions className={globalStyles.dialogActionsAlignRight}>
        <Button className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularPrimary}`} onClick={handleOkClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
