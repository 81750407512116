import React from "react";
import { Hedgerow } from '../../models/plot';
import { HedgerowListItem } from "./hedgerowListItem";
import styles from './hedgerowList.module.css';

export interface HedgerowListProps {
  hedgerows: Hedgerow[];
  selectedHedgerow?: Hedgerow
  canEdit: boolean;
  canSelect: boolean;
  onHedgerowEdit?: (hedgeRow: Hedgerow) => void;
  onHedgerowDelete?: (hedgeRow: Hedgerow) => void;
  onHedgerowAreaEdit?: (hedgeRow: Hedgerow) => void;
  onHedgerowSelect?: (hedgeRow: Hedgerow) => void;
}

export const HedgerowList = ({
  hedgerows,
  selectedHedgerow,
  canEdit,
  canSelect,
  onHedgerowEdit,
  onHedgerowDelete,
  onHedgerowAreaEdit,
  onHedgerowSelect }: HedgerowListProps): JSX.Element => {

  //Occurs when the EDIT button is clicked, passthrough to parent
  const handleEditHedgerowClick = async (hedgerow: Hedgerow) => {
    onHedgerowEdit?.(hedgerow);
  }

  //Occurs when the EDIT AREA button is clicked, passthrough to parent
  const handleEditHedgerowAreaClick = async (hedgerow: Hedgerow) => {
    onHedgerowAreaEdit?.(hedgerow);
  }

  //Occurs when the DELETE button is clicked, passthrough to parent
  const handleDeleteHedgerowClick = async (hedgerow: Hedgerow) => {
    onHedgerowDelete?.(hedgerow);
  }

  //Occurs when the hedgerow is clicked, passthrough to parent
  const handleHedgerowSelectClick = async (hedgerow: Hedgerow) => {
    onHedgerowSelect?.(hedgerow);
  }

  return (
    <>
      <div className={styles.hedgerowListContainer}>

        {/* Listing all the hedgerows */}
        {hedgerows.map((hedgerow, index) => (
          <div key={hedgerow.id}>

            {/* Only inset a divider if the first hedgerow in the list */}
            {index === 0 && (
              <div className={styles.topDivider} />
            )}

            <HedgerowListItem
              canSelect={canSelect}
              canEdit={canEdit}
              hedgerow={hedgerow}
              selected={hedgerow.id === selectedHedgerow?.id}
              onEditClick={handleEditHedgerowClick}
              onDeleteClick={handleDeleteHedgerowClick}
              onEditAreaClick={handleEditHedgerowAreaClick}
              onSelectClick={handleHedgerowSelectClick}
            />

            {/* Only inset a divider if not the last hedgerow int he list */}
            {index + 1 < hedgerows.length && (
              <div className={styles.bottomDivider} />
            )}
          </div>
          ))}
      </div>
    </>
  );
};
