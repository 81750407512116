import { useState, useEffect } from "react";

import { ButtonBase, Button, SvgIcon, Dialog,DialogActions, DialogContent,  DialogTitle, TextField} from '@mui/material';
import { styled } from '@mui/material/styles';

import globalStyles from '../../styles/global.module.css';
import { CreateOutlined, FormatListNumbered, UploadFile } from '@mui/icons-material';
import { Plot } from "../../models/plot";
import React from "react";
import { createNewHedgerow, createNewPlot } from "../../helpers/plotHelper";
export const CreatePlotKind = {
  DrawArea: 'DrawArea',
  EnterCoords: 'EnterCoords',
  UploadFile: 'UploadFile',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CreatePlotKind = typeof CreatePlotKind[keyof typeof CreatePlotKind];

export interface CreatePlotDialogProps {
  open: boolean;
  onCreated: (plot: Plot, postcode: string) => void;
  onCancelled: () => void;
}

export const CreatePlotDialog = ({
  open,
  onCreated,
  onCancelled
}: CreatePlotDialogProps): JSX.Element => {

  const [createAreaBy, setCreateAreaBy] = useState<CreatePlotKind>(null);
  const [plotName, setPlotName] = useState<string>(null);
  const [plotPostcode, setPlotPostcode] = useState<string>(null);
  const [showErrorText] = useState<boolean>(false);
  const defaultInputRef = React.useRef(null);

  const LargeButton = styled(ButtonBase)(() => ({
  }));

  useEffect(() => {
    //Reset the states on dialog open
    if (open) {
      setCreateAreaBy(CreatePlotKind.DrawArea);
      setPlotName(null);
    }

    //Delay setting focus as default "autofocus" doesn't seem to work
    //https://github.com/mui/material-ui/issues/1594
    const timeout = setTimeout(() => {
      defaultInputRef.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };

  }, [open]);


  //Occurs when CANCEL button is clicked
  const onClickCancel = () => {
    if (onCancelled) onCancelled();
  }

  //Occurs when CREATE button is clicked
  const onClickCreate = () => {

    //Not all fields specified, so don't close
    if (!plotName || !createAreaBy) return;

    //Create the plot with empty hedgerow
    const newPlot = createNewPlot(plotName);
    newPlot.hedgeRows.push(createNewHedgerow())

    //Callback to parent
    if (onCreated) onCreated(newPlot, plotPostcode);
  }

  return (
    <Dialog className={globalStyles.Dialog} open={open}>
      <DialogTitle className={globalStyles.dialogTitle}>Add Land</DialogTitle>

      <DialogContent>
        <div className={globalStyles.inputControl}>
          <label>Name</label>
          <TextField
            //inputRef={(input) => {  if (input) input.focus(); }}  //This doesn't seem to work?
            inputRef={defaultInputRef}
            required
            fullWidth
            variant="outlined"
            id="plotName"
            onChange={(event) => { setPlotName(event.target.value)}}
            error={!plotName}
            helperText={showErrorText && !plotName ? "Plot name is required" : ""}
            autoComplete="off" //Disable auto complete
          />
        </div>

        <div className={globalStyles.inputControl}>
          <label>Postcode</label>
          <TextField
            //inputRef={(input) => {  if (input) input.focus(); }}  //This doesn't seem to work?
            fullWidth
            variant="outlined"
            id="plotPostcode"
            onChange={(event) => { setPlotPostcode(event.target.value)}}
            autoComplete="off" //Disable auto complete
          />
        </div>

        <div className={`${globalStyles.inputControl} ${globalStyles.inputControlHidden}`}>
          <label>Create Area By</label>
          <div className={globalStyles.buttonLargeGroup}>
            <LargeButton
              className={globalStyles.buttonLarge}
              focusRipple
              aria-selected={createAreaBy === CreatePlotKind.DrawArea}
              onClick={() => {setCreateAreaBy(CreatePlotKind.DrawArea)}}
              >
              <SvgIcon component={CreateOutlined}></SvgIcon>
              <label>Draw Area</label>
            </LargeButton>

            <LargeButton
              className={globalStyles.buttonLarge}
              focusRipple
              aria-selected={createAreaBy === CreatePlotKind.EnterCoords}
              onClick={() => {setCreateAreaBy(CreatePlotKind.EnterCoords)}}
              aria-disabled={true}
              disabled={true}
            >
              <SvgIcon component={FormatListNumbered}></SvgIcon>
              <label>Enter Coordinates</label>
            </LargeButton>

            <LargeButton
              className={globalStyles.buttonLarge}
              focusRipple
              aria-selected={createAreaBy === CreatePlotKind.UploadFile}
              onClick={() => { setCreateAreaBy(CreatePlotKind.UploadFile)}}
              aria-disabled={true}
              disabled={true}
            >
              <SvgIcon component={UploadFile}></SvgIcon>
              <label>Upload File <br/> (.shp or .kmz)</label>
            </LargeButton>
          </div>

          {showErrorText && !createAreaBy && <p className={globalStyles.simpleErrorLabel}>An option must be selected</p>}

        </div>
      </DialogContent>

      <DialogActions className={globalStyles.dialogActionsAlignRight}>
        <Button className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey}`} onClick={onClickCancel}>
            Cancel
        </Button>
        <Button className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularPrimary}`} onClick={onClickCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
