import { v4 as uuid } from 'uuid';
import { Hedgerow, HedgerowAge, HedgerowCondition, HedgerowFertiliser, HedgerowHeight, HedgerowStyle, HedgerowWidth, Plot } from "../models/plot";
import { DateTime } from "luxon";

//Create an empty plot
export const createNewPlot = (name: string): Plot => {
  const newPlot: Plot = {
    id: uuid(),
    name: name,
    hedgeRows: []
  }

  return newPlot;
}

//Creates an empty (invalid) hedgerow
export const createNewHedgerow = (plot?: Plot): Hedgerow => {

  const newHedgerow: Hedgerow = {
    id: uuid(),
    lastUpdated: DateTime.now(),
    name: "Hedgerow",
    plantTypes: [],
    coords: [],
    areaMetreSquared: 0,
    age: HedgerowAge.Less5Years,
    condition: HedgerowCondition.H1,
    style: HedgerowStyle.Coppice,
    fertiliser: HedgerowFertiliser.None,
    height: HedgerowHeight.Less1Metre,
    width: HedgerowWidth.Less1Metre,
    baseCanopyMetres: 0,
    treeTypes: [],
    notes: '',
    state : {
      showInvalidIndicator: false,
      mapDrawData: null,
      mapId: null
    }
  }

  //If plot specified, then update name to next number in sequence
  if (plot) newHedgerow.name = `Hedgerow ${plot.hedgeRows.length + 1}`;

  return newHedgerow;
}

//Checks to see if the specified hedgerow is valid (all required fields specified)
export const isHedgerowValid = (hedgerow: Hedgerow, checkAreaAssigned: boolean = false): boolean => {

  if (hedgerow.age === null) return false;
  if (hedgerow.baseCanopyMetres === null || Number.isNaN(hedgerow.baseCanopyMetres) || (hedgerow.baseCanopyMetres <= 0)) return false;
  if (hedgerow.condition === null) return false;
  if (hedgerow.fertiliser === null) return false;
  if (hedgerow.height === null) return false;
  if (hedgerow.name === null || hedgerow.name === '') return false;
  if (hedgerow.plantTypes === null || hedgerow.plantTypes.length === 0) return false;
  if (hedgerow.style === null) return false;
  if (hedgerow.width === null) return false;

  if (checkAreaAssigned && (hedgerow.coords === undefined || hedgerow.coords.length === 0)) return false;

  //Reached here, all must be good :)
  return true;
}

//Checks to see if an entire plot with child data is valid (ready for submission)
export const isPlotValid = (plot: Plot): boolean => {

  //CHeck plot properties
  if (plot.name === null || plot.name === '') return false;

  //Must have at least one hedgerow
  if (plot.hedgeRows.length === 0) return false;

  //ALl hedgerows must be valid
  for (const hedgerow of plot.hedgeRows) {
    if (!isHedgerowValid(hedgerow, true)) return false;
  }

  //Already submitted
  if (plot.submissionInfo) return false;

  //Reached here, all must be good :)
  return true
}

//Determines is a plot is submitted or not
export const isPlotSubmitted = (plot: Plot): boolean => {
  return (plot.submissionInfo !== undefined) && (plot.submissionInfo !== null);
}

//Creates a deep clone of the plot, with option to remove map data properties
export const deepClonePlot = (plot: Plot, removeState: boolean = false): Plot => {

  const clonedPlot = JSON.parse(JSON.stringify(plot));

  clonedPlot.hedgeRows.forEach((hedgerow) => {
    hedgerow.lastUpdated = DateTime.fromISO(hedgerow.lastUpdated);    //Ensure DateTime is still object and not string
    if (removeState) delete hedgerow.state;
  });

  return clonedPlot;
}
