import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import globalStyles from '../../styles/global.module.css';

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  description: string;
  acceptText?: string;
  rejectText?: string;
  onButtonClick: (accepted: boolean) => void;
}

export const ConfirmationDialog = ({
  open,
  title,
  description,
  acceptText,
  rejectText,
  onButtonClick,
}: ConfirmationDialogProps): JSX.Element => {
  const onAcceptClick = () => {
    onButtonClick(true);
  };

  const onRejectClick = () => {
    onButtonClick(false);
  };

  return (
    <Dialog className={globalStyles.Dialog} open={open}>
      <DialogTitle className={globalStyles.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={globalStyles.dialogContent}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </DialogContent>
      <DialogActions className={globalStyles.dialogActionsAlignRight}>
        <Button className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularPrimary}`} onClick={onAcceptClick}>{acceptText ? acceptText : 'Ok'}</Button>
        <Button className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey}`} onClick={onRejectClick}>{rejectText ? rejectText : 'Cancel'}</Button>
      </DialogActions>
    </Dialog>
  );
};
