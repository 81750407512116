import React from "react";
import { Hedgerow } from '../../models/plot';
import { Square, Tune, Delete, Polyline, ErrorOutline } from '@mui/icons-material';
import { Button, IconButton } from "@mui/material";
import globalStyles from '../../styles/global.module.css';
import styles from './hedgerowListItem.module.css';
import { isHedgerowValid } from "../../helpers/plotHelper";

export interface HedgerowListItemProps {
  hedgerow: Hedgerow;
  selected: boolean;
  canEdit: boolean;
  canSelect: boolean;
  onEditClick?: (hedgerow: Hedgerow) => void;
  onDeleteClick?: (hedgerow: Hedgerow) => void;
  onEditAreaClick?: (hedgerow: Hedgerow) => void;
  onSelectClick?: (hedgerow: Hedgerow) => void;
}

export const HedgerowListItem = ({ hedgerow, selected, canEdit, canSelect, onEditClick, onDeleteClick, onEditAreaClick, onSelectClick }: HedgerowListItemProps): JSX.Element => {

  //Indicates whether the hedgerow has been drawn by setting icon colour
  const getIconColour = (hedgerow: Hedgerow, selected: boolean): string => {
    if (selected && hedgerow.coords.length > 0)
      return '#fbb03b'
    else if (hedgerow.coords.length > 0)
      return "#3BB2D0"
    else
      return "#E1E6EF"
  }

  //Occurs when the item is selected
  const handleSelectClick = () => {

    if (!canSelect) return;

    if (hedgerow.coords.length === 0) return;
    onSelectClick?.(hedgerow);
  }

  const getButtonDrawClasses = (hedgerow: Hedgerow): string => {

    let classes = globalStyles.buttonRegular;
    
    //Invalid and showing invalid indicators
    if (hedgerow.coords.length === 0 && hedgerow.state.showInvalidIndicator) {
      classes += ` ${globalStyles.buttonRegularInvalid}`;
    }
    //Invalid and NOT showing invalid indicators
    else if (hedgerow.coords.length === 0 && !hedgerow.state.showInvalidIndicator) {
      classes += ` ${globalStyles.buttonRegularPrimary}`;
    }
    //Valid
    else {
      classes += ` ${globalStyles.buttonRegularGrey}`;
    }

    return classes
  }

  //Gets the style for the EDIT button
  const getEditButtonClasses = (hedgerow: Hedgerow): string => {

    let classes = globalStyles.buttonIconSquare;

    //Hedgerow details are VALID
    if (isHedgerowValid(hedgerow, false)) {
      classes += ` ${globalStyles.buttonRegularGrey}`;
    }
    //Hedgerow details are INVALID
    else {
      classes += ` ${globalStyles.buttonRegularInvalid}`;
    }

    return classes;
  }

  return (
    <div
      className={ canSelect && selected && hedgerow.coords.length > 0 ? styles.containerSelected : styles.container }
      onClick={handleSelectClick}>

      <div className={styles.icon}>
        <Square style={{color: getIconColour(hedgerow, selected)}} />
      </div>
      <div className={styles.title}>{hedgerow.name}</div>
      <div className={styles.actions}>
        {canEdit && (
          <IconButton
            className={`${globalStyles.buttonRegularGrey} ${globalStyles.buttonIconSquare}`}
            aria-label="Delete hedgerow"
            title="Delete hedgerow"
            size="small"
            onClick={() => onDeleteClick?.(hedgerow)}>
            <Delete fontSize="small" />
          </IconButton>
        )}
        <IconButton
          className={getEditButtonClasses(hedgerow)}
          aria-label="Edit hedgerow"
          title="Edit hedgerow"
          size="small"
          onClick={() => onEditClick?.(hedgerow)}
          sx={{marginLeft: "3px !important"}}>
          <Tune fontSize="small" />
        </IconButton>
      </div>
      <div className={styles.content}>
        <div className={styles.species}>{hedgerow.plantTypes.join(", ")}</div>

        {hedgerow.coords.length > 0 && (
          <div className={styles.area}>{(Math.round((hedgerow.areaMetreSquared) * 100) / 100).toLocaleString('en-GB')} m²</div>
        )}
        {hedgerow.coords.length === 0 && (
          <div className={styles.area}>-.-- m²</div>
        )}

        <div className={styles.lastUpdated}>{`${hedgerow.lastUpdated.monthLong}, ${hedgerow.lastUpdated.year}`}</div>

        {hedgerow.coords.length === 0 && (
            <div className={styles.draw}>
              <Button
                className={getButtonDrawClasses(hedgerow)}
                aria-label="Draw hedgerow area"
                title="Draw hedgerow area"
                onClick={() => onEditAreaClick?.(hedgerow)}>
                <Polyline className={globalStyles.buttonIcon} fontSize="small" />
                Draw Hedgerow
              </Button>
          </div>
        )}
      </div>

      {/* Show missing info if not valid but area drawn  */}
      {hedgerow.state.showInvalidIndicator && !isHedgerowValid(hedgerow, true) && (
          <div className={styles.footer}>
            <ErrorOutline fontSize="small" />
            Not all details specified.
          </div>
      )}

    </div>
  );
};
