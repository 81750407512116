import { Feature, Polygon, area, polygon}  from "@turf/turf"
import { Coords } from "../models/plot";

export const calculateAreaM2 = (coords: Coords[]): number => {

  const tempCoords: any = [];
  tempCoords.push(coords);

  //Convert back into feature polygon
  const feature: Feature<Polygon> = {
    geometry: {
      coordinates: [coords.map((coord) => [coord.longitude, coord.latitude])],
      type: "Polygon"
    },
    type: "Feature",
    properties: {}
  }

  //Calculate area
  const turfPolygon = polygon(feature.geometry.coordinates);
  return area(turfPolygon); //in m2
}

/* Implement and refactor plotMapPage.tsx to use the helper methods below once completed
   Probably a better option to add the below methods directly to the LibreMap component
//Gets the current map mode
export const getMapMode = (mapboxDraw: MapboxDraw): string => {
  return mapboxDraw.getMode();
}

//Sets the map to draw ('draw_polygon') mode
export const setMapModeToDraw = (mapboxDraw: MapboxDraw) => {
  mapboxDraw.changeMode('draw_polygon');
}

//Select a feature on the map
export const selectMapFeature = (mapboxDraw: MapboxDraw, id: string) => {
  mapboxDraw.changeMode('simple_select', { featureIds: [id] });
}

//Deletes a feature on the map
export const deleteMapFeature = (mapboxDraw: MapboxDraw, id: string) => {
  selectMapFeature(mapboxDraw, id);   //Select the feature
  mapboxDraw.trash();                 //Delete the feature
} */