import React from 'react';
import { Hedgerow, Plot } from '../../models/plot';
import { DrawData } from './libreMap';
import styles from './mapOverlay.module.css';

export interface MapOverlayProps {
  plot?: Plot;
  drawData?: DrawData;
  drawMode?: string;
  selectedHedgerow?: Hedgerow;
  selectedHedgerowNewDrawing?: Hedgerow;
}

export const MapOverlay = ({
  plot,
  drawData,
  drawMode,
  selectedHedgerow,
  selectedHedgerowNewDrawing
}: MapOverlayProps): JSX.Element => {

  return (

    <div className={styles.container}>
      {/* Plot details */}
      {plot && (
        <>
          <div>Plot Name: {plot.name}</div>
          <div>Plot Id: {plot.id}</div>
          <div>-------------------------------------------------------------------</div>
          <div>Map mode: {drawMode}</div>
          <div>Hedgerow (Selected): {selectedHedgerow?.name}</div>
          <div>Hedgerow (New Polygon): {selectedHedgerowNewDrawing?.name}</div>
        </>
      )}


      {/* Polygon geometry details */}
      {plot && drawData && (
        <>
          <div>-------------------------------------------------------------------</div>
          <div>Feature Kind: {drawData?.type}</div>
          <div>Feature ID: {drawData?.id}</div>
          <div>Feature Action: {drawData?.action}</div>
          <div>-------------------------------------------------------------------</div>
          <div className={styles.details}>
            <span>
              Latitude:
              {
                <ul className={styles.detailsList}>
                  {drawData?.coords[0]?.map((value, index) => (
                    <li key={index}>{value[1]}</li>
                  ))}
                </ul>
              }
            </span>
            <span>
              Longitude:
              {
                <ul className={styles.detailsList}>
                  {drawData?.coords[0]?.map((value, index) => (
                    <li key={index}>{value[0]}</li>
                  ))}
                </ul>
              }
            </span>
          </div>
        </>
      )}
    </div>
  )
};