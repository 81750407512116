import { Plot } from "../../models/plot";

const url = window.__RUNTIME_CONFIG__.REACT_APP_PLOTS_API_URL;

//Get postcode data from the postcodes.io API
export const postPlot = async (plot: Plot) : Promise<boolean> => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(plot),
  };
  
  const response = await fetch(url, requestOptions);

  //Throw error if the response is not ok
  if (response.status !== 200) {
      throw new Error(await response.text());
  }

  return true;
}